//Cherryl Susara, WNM 820 Technical Implementation, Directed Study
//Reference: React Forms https://reactjs.org/docs/forms.html
//Mozilla for HTML Datepicker https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local
//W3 Cards https://www.w3schools.com/howto/howto_css_cards.asp
//Uploading React to Server https://prolevelprogramming.com/how-to-host-react-web-app-bluehost/
import React, { Component } from 'react';
import"./habitgroovy.css";
import Logo from "./habitgroovylogo.png";
class App extends Component{

 
  constructor (props) {
    super(props)
    this.state = {value:''};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  
  }

  handleChange(event) {
    this.setState({
      value:event.target.value});
  }

  handleSubmit(event){
    alert('Great! Your robot will play lights and sounds to alert you when to perform your routine.' + this.state.value);
    event.preventDefault();
    }
    

  render() {
    return (




<div class="row">
<div class='topnav'>
<div class='topnav-right'>
<a class ="active" href="https://www.habitgroovy.com" > &#60; About Habit Groovy </a>
</div>
</div>

<div class="containermain">

<div class="container">

<div class="card">

<div class="red">

 Get groovy with a new habit.
</div>

<form onSubmit={ this.handleSubmit}>
            
<fieldset>
    
<label class="label">What habit would you like to build?</label>
<select id="choose" id="habit" name="habit">
    <option selected>Choose...</option>
    <option value="1">Go for a walk</option>
    <option value="2">Meditate</option>
    <option value="3">Ride a bicycle</option>
    <option value="4">Run a few miles</option>
    <option value="5">Other</option>
    </select> 
        
<label class="label">Start Date
<input type="date" id="startdate" name="startdate" />
<caption>End Date</caption>
<input type="date"id="enddate" name="enddate" />  
</label>

<label class="label">Start Time 
<input type="time" id="starttime" name="starttime"  /> 
<caption>End Time</caption>
<input type="time" id="endtime" name="endtime" />
</label>


          

<label class="label">How often will you do this routine?</label>


<select id="choose" id="frequency" name="frequency">
    <option selected>Choose...</option>
    <option value="1">Daily</option>
    <option value="2">Every Other Day</option>
</select> 
<label class="label2">Get success in habit building with at least 18 days of
repeated routine performance. Power up by following a consistent schedule on cue. </label>

</fieldset>

       
<div class="cardspace"></div>
<input type="submit" value="Let's Roll With This!" />
<div class="cardspace"></div>
</form>
        
      
<div class="cardspace"></div>
</div>
</div>
</div>
</div>
  
    
    );
  }
  
}

export default App;